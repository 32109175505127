import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API || '/api',
  timeout: 5000
})

service.interceptors.request.use(function(config) {
  if (sessionStorage.getItem('token')) {
    config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token')
  }
  // console.log(process.env.VUE_APP_BASE_API)
  return config
}, function(error) {
  console.log(error)
  return Promise.reject(error)
})

service.interceptors.response.use(res => {
  return res
}, error => {
  console.log(error)
  return Promise.reject(error)
})

export default service
