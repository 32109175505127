import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Store from '@/store/index.js'

const routes = [{
  path: '/',
  name: 'Login',
  meta: {
    needLogin: false
  },
  component: Login
},
{
  path: '/home',
  name: 'Home',
  meta: {
    needLogin: true
  },
  component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
},
{
  path: '/addSpider',
  name: 'AddSpider',
  meta: {
    needLogin: true
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/AddSpider.vue')
},
{
  path: '/editSpider',
  name: 'EditSpider',
  meta: {
    needLogin: true
  },
  component: () => import(/* webpackChunkName: "about" */ '../views/EditSpider.vue')
},
{
  path: '/addQuestion',
  name: 'AddQuestion',
  meta: {
    needLogin: true
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/AddQuestion.vue')
},
{
  path: '/editQuestion',
  name: 'EditQuestion',
  meta: {
    needLogin: true
  },
  component: () => import(/* webpackChunkName: "about" */ '../views/EditQuestion.vue')
},
{
  path: '/addNote',
  name: 'AddNote',
  meta: {
    needLogin: true
  },
  component: () => import(/* webpackChunkName: "about" */ '../views/AddNote.vue')
},
{
  path: '/mianjing',
  name: 'Mianjing',
  meta: {
    needLogin: true
  },
  component: () => import(/* webpackChunkName: "about" */ '../views/Mianjing.vue')
},
{
  path: '/anser',
  name: 'Anser',
  meta: {
    needLogin: true
  },
  component: () => import(/* webpackChunkName: "about" */ '../views/Anser.vue')
}]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(function(to, from, next) {
  if (to.meta.needLogin) {
    if (Store.state.token) {
      // console.log(Store.state.token)
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})

export default router
