<template>
  <div class="add">
    <img src="../assets/logo_icon.png" width="150">
    <form>
      <label>
        <span>手机号</span>
        <input type="tel" name="tel" v-model.trim="tel">
      </label>
      <label>
        <span>密码</span>
        <input type="password" name="password" v-model.trim="password">
      </label>
      <label>
        <input type="submit" class="login_btn" value="提交" @click="submit($event)">
      </label>
    </form>
  </div>
</template>
<script>
// import axios from 'axios'
export default {
  name: 'Login',
  data() {
    return {
      tel: '',
      password: ''
    }
  },
  methods: {
    submit(event) {
      event.preventDefault()
      this.$store.dispatch('login', {
        mobile: this.tel,
        password: this.password
      }).then(res => {
        this.$router.push('/home')
      }, err => {
        console.log(err)
      })
    }
  }
}

</script>
<style scoped lang="less">
.add {
  width:400px;
  margin: 30px auto;
  form {
    width: 100%;
    overflow: hidden;
    label {
      display: block;
      margin: 20px auto;
      width: 100%;
      span {
        display: inline-block;
        width: 60px;
        text-align: left;
      }
      input {
        width: 330px;
        height: 20px;
        border: none;
      }
      .login_btn {
        width: 100%;
        height: 25px;
        line-height: 25px;
        background: #f6d172;
        color: #fff;
        border: none;
      }
    }
  }
}

</style>
