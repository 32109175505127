import { createStore } from 'vuex'
import { login } from '@/api/login.js'

export default createStore({
  state: {
    token: sessionStorage.getItem('token') || ''
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload
    }
  },
  actions: {
    async login(context, payload) {
      const params = payload
      const res = await login(params)
      // console.log(res.data.data)
      const token = res.data.data
      context.commit('setToken', token)
      sessionStorage.setItem('token', token)
      return res
    }
  },
  getters: {
    token: state => {
      return state.token
    }
  },
  modules: {
  }
})
