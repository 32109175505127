<template>
  <div class="navbar">
    <div class="nav" :class="[navShow?'':'navhide']">
      <div>
        <div class="head" @click="toggle">
          <img src="../assets/logo_icon.png" width="100">
        </div>
        <router-link to="/home">Home</router-link>
        <router-link to="/addSpider">添加网站信息</router-link>
        <router-link to="/editSpider">数据分类</router-link>
        <router-link to="/addQuestion">添加题库</router-link>
        <router-link to="/addNote">添加知识点</router-link>
        <router-link to="/mianjing">审核提问</router-link>
        <router-link to="/anser">审核回答</router-link>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      navShow: true
    }
  },
  props: {
    msg: String
  },
  methods: {
    toggle() {
      this.navShow = !this.navShow
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: #eee;

  .nav {
    float: left;
    width: 200px;
    height: 100%;
    background: #6d72bb;
    color: #fff;
    overflow: auto;
    a {
      display: block;
      color: #fff;
      text-decoration: none;
      margin: 15px auto;
    }
  }

  .navhide {
    width: 50px;
    img {
      width: 50px;
    }
  }

  .content {
    width: auto;
    height: 100%;
    overflow: auto;
  }
}
</style>
